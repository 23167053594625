
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["vuex_status_bar_height"]),
  },
  computed: {
    status_bar_height: function () {
      if (process.client) {
        return this.vuex_status_bar_height || 0;
      }
    },
    historyLength: function () {
      if (process.client) {
        // console.error(window.history);
        return window.history.length;
      }
    },
  },
  created() {
    let isApp = this.$cookies.get("isApp");
    this.isApp = isApp ? true : false;
  },
  methods: {
    back() {
      if (this.historyLength > 1) {
        this.$router.back();
      } else {
        this.$router.replace({
          path: "/",
        });
      }
    },
  },
  props: {
    title: String,
    fixed: {
      type: Boolean,
      default: true,
    },
    zIndex: [Number, String],
    leftText: String,
    rightText: String,
    leftArrow: Boolean,
    placeholder: Boolean,
    safeAreaInsetTop: Boolean,
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isApp: true
    };
  },
};
