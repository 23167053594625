"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.actions = exports.mutations = exports.state = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _data = _interopRequireDefault(require("~/assets/js/data.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

_vue["default"].use(_vuex["default"]);

var state = function state() {
  return {
    vuex_env_available: false,
    //是否支持一键登录
    vuex_individualization: true,
    //个性化推荐
    vuex_province_mode: '',
    vuex_include_arr: ["examIndex", 'majorList', 'schoolList', 'major', 'comprehensive', 'alumniAssociation', 'general', 'fillFilling', 'schoolExamination', "researchList", "rankList", 'fillTableInfo'],
    token: '',
    vuex_app_serve: 'uniapp',
    //是用什么平台打包 uniapp或apicloud
    vuex_device: 'NOHUAWEI',
    //设备
    vuex_buy_tips: '解锁全部信息',
    vuex_config_top_menu: _data["default"].config.top_menu,
    vuex_config: {},
    //配置
    vuex_status_bar_height: 0,
    //状态栏高度
    vuex_system: 'H5',
    //系统类型
    vuex_show_general: true,
    //是否显示简章
    vuex_show_buy: true,
    //是否显示购买
    vuex_examine: false,
    //是否考试
    vuex_geetest_appid: '692db42642377f976123797cb5c06210',
    // 极验appid
    vuex_geetest_h5_appid: '56e263169efc7c091479c8ef7fdf4b0a',
    // 极验h5appid
    vuex_pre_login: false,
    //是否可以使用一键登录
    vuex_h5_pre_login: false,
    //是否可以使用h5一键登录
    vuex_installed_wx: false,
    // 是否安装了微信
    vuex_user_info: {},
    vuex_provinces_list: _data["default"].provinceList,
    //省份列表
    vuex_art_type: [],
    //一级类
    vuex_subject_arr: [],
    //选科列表
    vuex_province_id: '',
    //省份id
    vuex_province_name: '',
    //省份名称
    vuex_report_info: {},
    //志愿表详情信息
    vuex_batch_name: '',
    //志愿表批次名称
    vuex_report_detail: {},
    vuex_table_major_planids: [],
    // 志愿表选中的专业列表
    vuex_table_school_planids: [],
    //志愿表选中的院校列表
    vuex_table_group_codes: [],
    vuex_table_college_form_codes: [],
    vuex_show_ios_pay: '',
    vuex_non_empty_plan_ids: [],
    // 已选中的计划ids
    vuex_change_school: {} // 对应院校修改专业列表

  };
}; // 修改state里面的函数必须用到mutations里面的函数


exports.state = state;
var mutations = {
  SET_ENV_AVAILABLE: function SET_ENV_AVAILABLE(state, result) {
    state.vuex_env_available = result;
  },
  // 设置被选中的计划ids
  set_non_empty_plan_ids: function set_non_empty_plan_ids(state, data) {
    state.vuex_non_empty_plan_ids = data;
  },
  set_change_school: function set_change_school(state, data) {
    state.vuex_change_school = data;
  },
  // 设置个性化推荐
  set_individualization: function set_individualization(state, data) {
    state.vuex_individualization = data;
    this.$cookies.set('vuex_individualization', data);
  },
  set_app_serve: function set_app_serve(state, data) {
    state.vuex_app_serve = data;
  },
  set_token: function set_token(state, data) {
    state.token = data;
  },
  set_show_general: function set_show_general(state, data) {
    state.vuex_show_general = data;
  },
  set_show_buy: function set_show_buy(state, data) {
    state.vuex_show_buy = data;
  },
  set_status_bar_height: function set_status_bar_height(state, data) {
    state.vuex_status_bar_height = data;
  },
  set_pre_login: function set_pre_login(state, data) {
    state.vuex_pre_login = data;
  },
  set_h5_pre_login: function set_h5_pre_login(state, data) {
    state.vuex_h5_pre_login = data;
  },
  set_installed_wx: function set_installed_wx(state, data) {
    state.vuex_installed_wx = data;
  },
  // 设置系统类型
  set_system: function set_system(state, data) {
    state.vuex_system = data;
  },
  set_device: function set_device(state, data) {
    state.vuex_device = data;
  },
  set_include_arr: function set_include_arr(state, data) {
    state.vuex_include_arr = data;
  },
  set_user_info: function set_user_info(state, data) {
    state.token = data.token;
    state.vuex_user_info = data;
    state.vuex_province_id = data.province_id;
    this.$cookies.set('token', data.token);
    this.$cookies.set('vuex_user_info', data);
  },
  set_table_group_codes: function set_table_group_codes(state, data) {
    state.vuex_table_group_codes = data;
  },
  set_table_college_form_codes: function set_table_college_form_codes(state, data) {
    state.vuex_table_college_form_codes = data;
  },
  set_table_school_planids: function set_table_school_planids(state, data) {
    state.vuex_table_school_planids = data;
  },
  set_table_major_planids: function set_table_major_planids(state, data) {
    state.vuex_table_major_planids = data;
  },
  set_report_detail: function set_report_detail(state, data) {
    state.vuex_report_detail = data;
  },
  set_batch_name: function set_batch_name(state, data) {
    state.vuex_batch_name = data;
  },
  set_provinces_list: function set_provinces_list(state, data) {
    state.vuex_provinces_list = data;
  },
  set_province_mode: function set_province_mode(state, data) {
    state.vuex_province_mode = data;
  },
  set_art_type: function set_art_type(state, data) {
    state.vuex_art_type = data;
  },
  set_subject_arr: function set_subject_arr(state, data) {
    state.vuex_subject_arr = data;
  },
  set_province_id: function set_province_id(state, data) {
    state.vuex_province_id = data;
  },
  set_province_name: function set_province_name(state, data) {
    state.vuex_province_name = data;
  },
  set_config: function set_config(state, data) {
    state.vuex_config = data;
  },
  set_report_info: function set_report_info(state, data) {
    state.vuex_report_info = data;
  }
};
exports.mutations = mutations;
var actions = {
  // 服务初始化 持久化
  nuxtServerInit: function nuxtServerInit(_ref, _ref2) {
    var commit = _ref.commit,
        dispatch = _ref.dispatch;
    var req = _ref2.req;
    var token = this.$cookies.get('token'); // 个性化推荐的缓存更新

    var vuex_individualization = this.$cookies.get('vuex_individualization');

    if (vuex_individualization == false) {
      commit('set_individualization', false);
    } else {
      commit('set_individualization', true);
    } // let vuex_config = this.$cookies.get('vuex_config');
    // if (vuex_config) {
    //   commit('set_config', params)
    // }


    if (token) {
      commit('set_token', token);
    } // if (vuex_config) {
    //   commit('set_config', vuex_config);
    // }

  },
  setEnvAvailable: function setEnvAvailable(_ref3, result) {
    var commit;
    return regeneratorRuntime.async(function setEnvAvailable$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            commit = _ref3.commit;
            commit('SET_ENV_AVAILABLE', result);

          case 2:
          case "end":
            return _context.stop();
        }
      }
    });
  },
  // 获取用户信息
  getUserInfo: function getUserInfo(_ref4, val) {
    var _this = this;

    var dispatch = _ref4.dispatch,
        commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      _this.$axios.get("/app/artapi.php?r=user.get_user_info").then(function (_ref5) {
        var data = _ref5.data;

        if (data.code == 200) {
          commit('set_user_info', data.data);

          if (process.client) {
            localStorage.setItem('token', data.data.token);
            localStorage.setItem('user_info', JSON.stringify(data.data)); // if (!data.data.vendor) {

            window.uni.postMessage({
              data: {
                name: "evalJs",
                value: "\n                    let brand = uni.getSystemInfoSync().brand;\n                    if(brand){\n                      let pages = getCurrentPages();\n                      let page = pages[pages.length - 1];\n                      let currentWebview = page.$getAppWebview();\n                      let wv = currentWebview.children()[0];\n                      wv.evalJS(\"bind_vendor_oaid({vendor: '\" + brand + \"'})\");\n                    }\n                    plus.device.getOAID({\n                      success: function(e) {\n                      if (e.oaid) {\n                        let pages = getCurrentPages();\n                        let page = pages[pages.length - 1];\n                        let currentWebview = page.$getAppWebview();\n                        let wv = currentWebview.children()[0];\n                        wv.evalJS(\"bind_vendor_oaid({vendor: '\" + brand + \"',oaid: '\" + e.oaid + \"'})\");\n                      }\n                      }\n                    });"
              }
            }); // }
          }

          resolve(_objectSpread({}, data.data));
        }
      });
    });
  },
  // 微信公众号获取用户信息
  WXMEBOath: function WXMEBOath(_ref6, val) {
    var _this2 = this;

    var dispatch = _ref6.dispatch,
        commit = _ref6.commit;
    return new Promise(function (resolve, reject) {
      var url = _this2.$cookies.get('href');

      url = url ? url : 'https://m.yikaotianbao.cn';
      url = 'https://m.yikaotianbao.cn';
      var params = {
        url: url
      };

      _this2.$axios.get("/app/artapi.php?r=ralfqbk.get_authurl", {
        params: params
      }).then(function (_ref7) {
        var data = _ref7.data;

        if (data.code == 200) {
          resolve(_objectSpread({}, data.data));
          window.location.href = data.data.url;
        }
      });
    });
  },
  // 获取志愿表详情
  getReportDetailList: function getReportDetailList(_ref8, params) {
    var _this3 = this;

    var dispatch = _ref8.dispatch,
        commit = _ref8.commit;
    return new Promise(function (resolve, reject) {
      var url = "/app/artapi.php?r=newquery.get_report_detail_list"; // if(params.xiaokao == 1){
      //   url = '/app/artapi.php?r=newquery.xk_recommend_list';
      // }

      _this3.$axios.$post(url, params).then(function (res) {
        resolve(res);

        if (res.code == 200) {
          var detail = res.data;
          _this3.school_list = detail;

          if (detail.school_list && detail.school_list.length >= 1) {
            var vuex_table_school_planids = [];
            var vuex_table_group_codes = [];
            var vuex_table_college_form_codes = [];
            detail.school_list.map(function (item) {
              if (item.plan_id && item.plan_id != 0) {
                vuex_table_school_planids.push(item.plan_id + "");
              } else {
                vuex_table_school_planids.push("-1");
              }

              if (item.group_code && item.group_code != 0) {
                vuex_table_group_codes.push(item.group_code + "");
              } else {
                vuex_table_group_codes.push("-1");
              }

              if (item.college_form_code && item.college_form_code != 0) {
                vuex_table_college_form_codes.push(item.college_form_code + "");
              } else {
                vuex_table_college_form_codes.push("-1");
              }
            });
            commit("set_table_school_planids", vuex_table_school_planids);
            commit("set_table_group_codes", vuex_table_group_codes);
            commit("set_table_college_form_codes", vuex_table_college_form_codes);
          } else {
            detail["school_list"] = [];
          }

          if (detail.major_list && detail.major_list.length >= 1) {
            var vuex_table_major_planids = [];
            detail.major_list.map(function (item) {
              if (item.plan_id) {
                vuex_table_major_planids.push(item.plan_id + "");
              } else {
                vuex_table_major_planids.push("-1");
              }
            });
            commit("set_table_major_planids", vuex_table_major_planids);
          }

          commit("set_report_detail", detail);
        }
      });
    });
  },
  // 获取通用配置
  getConfig: function getConfig(_ref9, val) {
    var _this4 = this;

    var dispatch = _ref9.dispatch,
        commit = _ref9.commit;
    return new Promise(function (resolve, reject) {
      _this4.$axios.get("/app/artapi.php?r=getconfig.get_config").then(function (_ref10) {
        var data = _ref10.data;

        if (data.code == 200) {
          commit('set_config', data.data);
        }
      });
    });
  },
  getReportInfo: function getReportInfo(_ref11, val) {
    var _this5 = this;

    var dispatch = _ref11.dispatch,
        commit = _ref11.commit;
    return new Promise(function (resolve, reject) {
      console.log(val);

      _this5.$axios.$post("/app/artapi.php?r=newquery.get_report_info", {
        id: val
      }).then(function (data) {
        if (data.code == 200) {
          commit('set_report_info', data.data);
        }
      });
    });
  }
};
exports.actions = actions;
var _default = {
  state: state,
  mutations: mutations,
  actions: actions
};
exports["default"] = _default;