import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4395783a = () => interopDefault(import('../pages/exam/index.vue' /* webpackChunkName: "pages/exam/index" */))
const _f663805e = () => interopDefault(import('../pages/filling/index.vue' /* webpackChunkName: "pages/filling/index" */))
const _7013baf8 = () => interopDefault(import('../pages/index copy.vue' /* webpackChunkName: "pages/index copy" */))
const _f194fad4 = () => interopDefault(import('../pages/index copy 2.vue' /* webpackChunkName: "pages/index copy 2" */))
const _55ac774b = () => interopDefault(import('../pages/major/index.vue' /* webpackChunkName: "pages/major/index" */))
const _5ac0d08f = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _81048b98 = () => interopDefault(import('../pages/wiki/index.vue' /* webpackChunkName: "pages/wiki/index" */))
const _fe736fe0 = () => interopDefault(import('../pages/exam/detail.vue' /* webpackChunkName: "pages/exam/detail" */))
const _2b4ec7e0 = () => interopDefault(import('../pages/exam/record.vue' /* webpackChunkName: "pages/exam/record" */))
const _246eb984 = () => interopDefault(import('../pages/exam/wrong.vue' /* webpackChunkName: "pages/exam/wrong" */))
const _20f96207 = () => interopDefault(import('../pages/filling/bookmark.vue' /* webpackChunkName: "pages/filling/bookmark" */))
const _72939730 = () => interopDefault(import('../pages/filling/catalogue.vue' /* webpackChunkName: "pages/filling/catalogue" */))
const _732d9fdd = () => interopDefault(import('../pages/filling/catalogue copy.vue' /* webpackChunkName: "pages/filling/catalogue copy" */))
const _2f248ef0 = () => interopDefault(import('../pages/filling/diagnosis.vue' /* webpackChunkName: "pages/filling/diagnosis" */))
const _0840cff3 = () => interopDefault(import('../pages/filling/down.vue' /* webpackChunkName: "pages/filling/down" */))
const _5b9d6b84 = () => interopDefault(import('../pages/filling/filling.vue' /* webpackChunkName: "pages/filling/filling" */))
const _642e2bba = () => interopDefault(import('../pages/filling/researchDeatil.vue' /* webpackChunkName: "pages/filling/researchDeatil" */))
const _4680e0ac = () => interopDefault(import('../pages/filling/researchList.vue' /* webpackChunkName: "pages/filling/researchList" */))
const _ac16ba98 = () => interopDefault(import('../pages/filling/researchSearch.vue' /* webpackChunkName: "pages/filling/researchSearch" */))
const _5edd5948 = () => interopDefault(import('../pages/filling/smart.vue' /* webpackChunkName: "pages/filling/smart" */))
const _4a60348c = () => interopDefault(import('../pages/filling/smartIm.vue' /* webpackChunkName: "pages/filling/smartIm" */))
const _08568d1d = () => interopDefault(import('../pages/filling/step.vue' /* webpackChunkName: "pages/filling/step" */))
const _3e6974cd = () => interopDefault(import('../pages/filling/table.vue' /* webpackChunkName: "pages/filling/table" */))
const _f6807cf6 = () => interopDefault(import('../pages/filling/tableAddMajor.vue' /* webpackChunkName: "pages/filling/tableAddMajor" */))
const _f71dd7ca = () => interopDefault(import('../pages/filling/tableInfo.vue' /* webpackChunkName: "pages/filling/tableInfo" */))
const _5b580fdc = () => interopDefault(import('../pages/login/login.vue' /* webpackChunkName: "pages/login/login" */))
const _090e88d2 = () => interopDefault(import('../pages/login/phone.vue' /* webpackChunkName: "pages/login/phone" */))
const _0f94fcb0 = () => interopDefault(import('../pages/major/detail.vue' /* webpackChunkName: "pages/major/detail" */))
const _d2f42196 = () => interopDefault(import('../pages/major/list.vue' /* webpackChunkName: "pages/major/list" */))
const _037ccfbf = () => interopDefault(import('../pages/pageB/carerrDetail.vue' /* webpackChunkName: "pages/pageB/carerrDetail" */))
const _22a7d70c = () => interopDefault(import('../pages/pageB/carerrList.vue' /* webpackChunkName: "pages/pageB/carerrList" */))
const _28e72287 = () => interopDefault(import('../pages/pageB/carSpecialty.vue' /* webpackChunkName: "pages/pageB/carSpecialty" */))
const _7af63122 = () => interopDefault(import('../pages/pageB/carSpecialtyMajor.vue' /* webpackChunkName: "pages/pageB/carSpecialtyMajor" */))
const _5e1ef13a = () => interopDefault(import('../pages/pageB/carSpecialtyMajor copy.vue' /* webpackChunkName: "pages/pageB/carSpecialtyMajor copy" */))
const _72d89fe1 = () => interopDefault(import('../pages/pageB/developDetail.vue' /* webpackChunkName: "pages/pageB/developDetail" */))
const _5ca2991f = () => interopDefault(import('../pages/pageB/developPage.vue' /* webpackChunkName: "pages/pageB/developPage" */))
const _67fb8a46 = () => interopDefault(import('../pages/pageB/enrollmentRule.vue' /* webpackChunkName: "pages/pageB/enrollmentRule" */))
const _82e234be = () => interopDefault(import('../pages/pageB/evaList.vue' /* webpackChunkName: "pages/pageB/evaList" */))
const _748d106c = () => interopDefault(import('../pages/pageB/evaProblem.vue' /* webpackChunkName: "pages/pageB/evaProblem" */))
const _5d8976e0 = () => interopDefault(import('../pages/pageB/evaResult.vue' /* webpackChunkName: "pages/pageB/evaResult" */))
const _7665a89e = () => interopDefault(import('../pages/pageB/evaResultList.vue' /* webpackChunkName: "pages/pageB/evaResultList" */))
const _3d8fce12 = () => interopDefault(import('../pages/pageB/filling.vue' /* webpackChunkName: "pages/pageB/filling" */))
const _6b09615b = () => interopDefault(import('../pages/pageB/industry.vue' /* webpackChunkName: "pages/pageB/industry" */))
const _23d931e8 = () => interopDefault(import('../pages/pageB/industryDetail.vue' /* webpackChunkName: "pages/pageB/industryDetail" */))
const _7cbb07f4 = () => interopDefault(import('../pages/pageB/luquRule.vue' /* webpackChunkName: "pages/pageB/luquRule" */))
const _37af4b3d = () => interopDefault(import('../pages/pageB/majorGoodSchool.vue' /* webpackChunkName: "pages/pageB/majorGoodSchool" */))
const _5a2e5c78 = () => interopDefault(import('../pages/pageB/majorTool.vue' /* webpackChunkName: "pages/pageB/majorTool" */))
const _770850de = () => interopDefault(import('../pages/pageB/organCompare.vue' /* webpackChunkName: "pages/pageB/organCompare" */))
const _34d17b93 = () => interopDefault(import('../pages/pageB/organPage.vue' /* webpackChunkName: "pages/pageB/organPage" */))
const _57a177d5 = () => interopDefault(import('../pages/pageB/planPage.vue' /* webpackChunkName: "pages/pageB/planPage" */))
const _95eef11e = () => interopDefault(import('../pages/pageB/postSchool.vue' /* webpackChunkName: "pages/pageB/postSchool" */))
const _77ffaa62 = () => interopDefault(import('../pages/pageB/postSchoolPoint.vue' /* webpackChunkName: "pages/pageB/postSchoolPoint" */))
const _7cc23e87 = () => interopDefault(import('../pages/pageB/powerPage.vue' /* webpackChunkName: "pages/pageB/powerPage" */))
const _25537515 = () => interopDefault(import('../pages/pageB/powerResult.vue' /* webpackChunkName: "pages/pageB/powerResult" */))
const _1116476e = () => interopDefault(import('../pages/pageB/provinceRule.vue' /* webpackChunkName: "pages/pageB/provinceRule" */))
const _63a2b1f9 = () => interopDefault(import('../pages/pageB/rule.vue' /* webpackChunkName: "pages/pageB/rule" */))
const _7b0cfe44 = () => interopDefault(import('../pages/pageB/studioDetail.vue' /* webpackChunkName: "pages/pageB/studioDetail" */))
const _230be2d1 = () => interopDefault(import('../pages/pageB/studioList.vue' /* webpackChunkName: "pages/pageB/studioList" */))
const _693cffe2 = () => interopDefault(import('../pages/pageB/volunChange.vue' /* webpackChunkName: "pages/pageB/volunChange" */))
const _555b0f78 = () => interopDefault(import('../pages/rank/alumni-association.vue' /* webpackChunkName: "pages/rank/alumni-association" */))
const _0c97fb9c = () => interopDefault(import('../pages/rank/comprehensive.vue' /* webpackChunkName: "pages/rank/comprehensive" */))
const _5d488aca = () => interopDefault(import('../pages/rank/default-rank.vue' /* webpackChunkName: "pages/rank/default-rank" */))
const _3a41c18c = () => interopDefault(import('../pages/school/contrast.vue' /* webpackChunkName: "pages/school/contrast" */))
const _5360bd4a = () => interopDefault(import('../pages/school/contrastList.vue' /* webpackChunkName: "pages/school/contrastList" */))
const _05440849 = () => interopDefault(import('../pages/school/contrastResult.vue' /* webpackChunkName: "pages/school/contrastResult" */))
const _be8fc84a = () => interopDefault(import('../pages/school/detail.vue' /* webpackChunkName: "pages/school/detail" */))
const _c0c24852 = () => interopDefault(import('../pages/school/examination.vue' /* webpackChunkName: "pages/school/examination" */))
const _5c485405 = () => interopDefault(import('../pages/school/forecast.vue' /* webpackChunkName: "pages/school/forecast" */))
const _0a143e6e = () => interopDefault(import('../pages/school/guarantee.vue' /* webpackChunkName: "pages/school/guarantee" */))
const _f1810bb0 = () => interopDefault(import('../pages/school/list.vue' /* webpackChunkName: "pages/school/list" */))
const _3e75e9da = () => interopDefault(import('../pages/school/recommendDetail.vue' /* webpackChunkName: "pages/school/recommendDetail" */))
const _7227d13e = () => interopDefault(import('../pages/school/recommendDetailInfo.vue' /* webpackChunkName: "pages/school/recommendDetailInfo" */))
const _b4a25140 = () => interopDefault(import('../pages/school/recommendList.vue' /* webpackChunkName: "pages/school/recommendList" */))
const _beab17c8 = () => interopDefault(import('../pages/shop/address.vue' /* webpackChunkName: "pages/shop/address" */))
const _0bcbd2a1 = () => interopDefault(import('../pages/shop/address_info.vue' /* webpackChunkName: "pages/shop/address_info" */))
const _5427245a = () => interopDefault(import('../pages/shop/create_order.vue' /* webpackChunkName: "pages/shop/create_order" */))
const _6c87617f = () => interopDefault(import('../pages/shop/goods_info.vue' /* webpackChunkName: "pages/shop/goods_info" */))
const _9bbd9522 = () => interopDefault(import('../pages/shop/goods_list.vue' /* webpackChunkName: "pages/shop/goods_list" */))
const _2cd78176 = () => interopDefault(import('../pages/shop/order_group.vue' /* webpackChunkName: "pages/shop/order_group" */))
const _da102ef2 = () => interopDefault(import('../pages/shop/order_info.vue' /* webpackChunkName: "pages/shop/order_info" */))
const _4edc8712 = () => interopDefault(import('../pages/shop/order_list.vue' /* webpackChunkName: "pages/shop/order_list" */))
const _01688e18 = () => interopDefault(import('../pages/tools/activationCode.vue' /* webpackChunkName: "pages/tools/activationCode" */))
const _bce36a8c = () => interopDefault(import('../pages/tools/baogao.vue' /* webpackChunkName: "pages/tools/baogao" */))
const _7c6e0110 = () => interopDefault(import('../pages/tools/baogaoList.vue' /* webpackChunkName: "pages/tools/baogaoList" */))
const _40f68355 = () => interopDefault(import('../pages/tools/batch.vue' /* webpackChunkName: "pages/tools/batch" */))
const _02ded7a1 = () => interopDefault(import('../pages/tools/bindPage.vue' /* webpackChunkName: "pages/tools/bindPage" */))
const _8d84ba16 = () => interopDefault(import('../pages/tools/browseLog.vue' /* webpackChunkName: "pages/tools/browseLog" */))
const _ea5d8c64 = () => interopDefault(import('../pages/tools/coinLog.vue' /* webpackChunkName: "pages/tools/coinLog" */))
const _46751664 = () => interopDefault(import('../pages/tools/collectPlan.vue' /* webpackChunkName: "pages/tools/collectPlan" */))
const _3c070f4e = () => interopDefault(import('../pages/tools/collectSchool.vue' /* webpackChunkName: "pages/tools/collectSchool" */))
const _57eb42aa = () => interopDefault(import('../pages/tools/configValue.vue' /* webpackChunkName: "pages/tools/configValue" */))
const _0ac6310e = () => interopDefault(import('../pages/tools/contactUs.vue' /* webpackChunkName: "pages/tools/contactUs" */))
const _9ceb8752 = () => interopDefault(import('../pages/tools/counter.vue' /* webpackChunkName: "pages/tools/counter" */))
const _66b0b2a4 = () => interopDefault(import('../pages/tools/datas.vue' /* webpackChunkName: "pages/tools/datas" */))
const _23700456 = () => interopDefault(import('../pages/tools/easterEgg.vue' /* webpackChunkName: "pages/tools/easterEgg" */))
const _7ed3fa3a = () => interopDefault(import('../pages/tools/feedback.vue' /* webpackChunkName: "pages/tools/feedback" */))
const _54809943 = () => interopDefault(import('../pages/tools/general.vue' /* webpackChunkName: "pages/tools/general" */))
const _09ab10f4 = () => interopDefault(import('../pages/tools/generalDetail.vue' /* webpackChunkName: "pages/tools/generalDetail" */))
const _6a8d5d96 = () => interopDefault(import('../pages/tools/inviteLog.vue' /* webpackChunkName: "pages/tools/inviteLog" */))
const _8f7c8a60 = () => interopDefault(import('../pages/tools/kf.vue' /* webpackChunkName: "pages/tools/kf" */))
const _2dc31908 = () => interopDefault(import('../pages/tools/news.vue' /* webpackChunkName: "pages/tools/news" */))
const _55fde8f9 = () => interopDefault(import('../pages/tools/newsDetail.vue' /* webpackChunkName: "pages/tools/newsDetail" */))
const _4cd72736 = () => interopDefault(import('../pages/tools/province.vue' /* webpackChunkName: "pages/tools/province" */))
const _2c3af43e = () => interopDefault(import('../pages/tools/rank.vue' /* webpackChunkName: "pages/tools/rank" */))
const _37048f12 = () => interopDefault(import('../pages/tools/rankDetail.vue' /* webpackChunkName: "pages/tools/rankDetail" */))
const _01ad4f1f = () => interopDefault(import('../pages/tools/rankList.vue' /* webpackChunkName: "pages/tools/rankList" */))
const _c92f24a6 = () => interopDefault(import('../pages/tools/signUp.vue' /* webpackChunkName: "pages/tools/signUp" */))
const _0c6a841a = () => interopDefault(import('../pages/tools/subsection.vue' /* webpackChunkName: "pages/tools/subsection" */))
const _a943a032 = () => interopDefault(import('../pages/tools/subsection_bak.vue' /* webpackChunkName: "pages/tools/subsection_bak" */))
const _4e593e8b = () => interopDefault(import('../pages/tools/subsectionDetail.vue' /* webpackChunkName: "pages/tools/subsectionDetail" */))
const _6a7d8fd4 = () => interopDefault(import('../pages/tools/video.vue' /* webpackChunkName: "pages/tools/video" */))
const _c30be110 = () => interopDefault(import('../pages/tools/vip.vue' /* webpackChunkName: "pages/tools/vip" */))
const _642fa266 = () => interopDefault(import('../pages/tools/vip copy.vue' /* webpackChunkName: "pages/tools/vip copy" */))
const _30df88ea = () => interopDefault(import('../pages/tools/vip2.vue' /* webpackChunkName: "pages/tools/vip2" */))
const _919fc21e = () => interopDefault(import('../pages/user/addSchoolExam.vue' /* webpackChunkName: "pages/user/addSchoolExam" */))
const _63e784dd = () => interopDefault(import('../pages/user/edit.vue' /* webpackChunkName: "pages/user/edit" */))
const _055f6db2 = () => interopDefault(import('../pages/user/editData.vue' /* webpackChunkName: "pages/user/editData" */))
const _aa2451a6 = () => interopDefault(import('../pages/user/editProvince.vue' /* webpackChunkName: "pages/user/editProvince" */))
const _43e748e5 = () => interopDefault(import('../pages/user/editScore.vue' /* webpackChunkName: "pages/user/editScore" */))
const _5c42a074 = () => interopDefault(import('../pages/user/schoolExam.vue' /* webpackChunkName: "pages/user/schoolExam" */))
const _8d59373e = () => interopDefault(import('../pages/school/components/SchoolInfo.vue' /* webpackChunkName: "pages/school/components/SchoolInfo" */))
const _7c960694 = () => interopDefault(import('../pages/school/components/TongKao.vue' /* webpackChunkName: "pages/school/components/TongKao" */))
const _131ee4eb = () => interopDefault(import('../pages/school/components/XiaoKao.vue' /* webpackChunkName: "pages/school/components/XiaoKao" */))
const _08f26561 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/exam",
    component: _4395783a,
    name: "exam"
  }, {
    path: "/filling",
    component: _f663805e,
    name: "filling"
  }, {
    path: "/index%20copy",
    component: _7013baf8,
    name: "index copy"
  }, {
    path: "/index%20copy%202",
    component: _f194fad4,
    name: "index copy 2"
  }, {
    path: "/major",
    component: _55ac774b,
    name: "major"
  }, {
    path: "/user",
    component: _5ac0d08f,
    name: "user"
  }, {
    path: "/wiki",
    component: _81048b98,
    name: "wiki"
  }, {
    path: "/exam/detail",
    component: _fe736fe0,
    name: "exam-detail"
  }, {
    path: "/exam/record",
    component: _2b4ec7e0,
    name: "exam-record"
  }, {
    path: "/exam/wrong",
    component: _246eb984,
    name: "exam-wrong"
  }, {
    path: "/filling/bookmark",
    component: _20f96207,
    name: "filling-bookmark"
  }, {
    path: "/filling/catalogue",
    component: _72939730,
    name: "filling-catalogue"
  }, {
    path: "/filling/catalogue%20copy",
    component: _732d9fdd,
    name: "filling-catalogue copy"
  }, {
    path: "/filling/diagnosis",
    component: _2f248ef0,
    name: "filling-diagnosis"
  }, {
    path: "/filling/down",
    component: _0840cff3,
    name: "filling-down"
  }, {
    path: "/filling/filling",
    component: _5b9d6b84,
    name: "filling-filling"
  }, {
    path: "/filling/researchDeatil",
    component: _642e2bba,
    name: "filling-researchDeatil"
  }, {
    path: "/filling/researchList",
    component: _4680e0ac,
    name: "filling-researchList"
  }, {
    path: "/filling/researchSearch",
    component: _ac16ba98,
    name: "filling-researchSearch"
  }, {
    path: "/filling/smart",
    component: _5edd5948,
    name: "filling-smart"
  }, {
    path: "/filling/smartIm",
    component: _4a60348c,
    name: "filling-smartIm"
  }, {
    path: "/filling/step",
    component: _08568d1d,
    name: "filling-step"
  }, {
    path: "/filling/table",
    component: _3e6974cd,
    name: "filling-table"
  }, {
    path: "/filling/tableAddMajor",
    component: _f6807cf6,
    name: "filling-tableAddMajor"
  }, {
    path: "/filling/tableInfo",
    component: _f71dd7ca,
    name: "filling-tableInfo"
  }, {
    path: "/login/login",
    component: _5b580fdc,
    name: "login-login"
  }, {
    path: "/login/phone",
    component: _090e88d2,
    name: "login-phone"
  }, {
    path: "/major/detail",
    component: _0f94fcb0,
    name: "major-detail"
  }, {
    path: "/major/list",
    component: _d2f42196,
    name: "major-list"
  }, {
    path: "/pageB/carerrDetail",
    component: _037ccfbf,
    name: "pageB-carerrDetail"
  }, {
    path: "/pageB/carerrList",
    component: _22a7d70c,
    name: "pageB-carerrList"
  }, {
    path: "/pageB/carSpecialty",
    component: _28e72287,
    name: "pageB-carSpecialty"
  }, {
    path: "/pageB/carSpecialtyMajor",
    component: _7af63122,
    name: "pageB-carSpecialtyMajor"
  }, {
    path: "/pageB/carSpecialtyMajor%20copy",
    component: _5e1ef13a,
    name: "pageB-carSpecialtyMajor copy"
  }, {
    path: "/pageB/developDetail",
    component: _72d89fe1,
    name: "pageB-developDetail"
  }, {
    path: "/pageB/developPage",
    component: _5ca2991f,
    name: "pageB-developPage"
  }, {
    path: "/pageB/enrollmentRule",
    component: _67fb8a46,
    name: "pageB-enrollmentRule"
  }, {
    path: "/pageB/evaList",
    component: _82e234be,
    name: "pageB-evaList"
  }, {
    path: "/pageB/evaProblem",
    component: _748d106c,
    name: "pageB-evaProblem"
  }, {
    path: "/pageB/evaResult",
    component: _5d8976e0,
    name: "pageB-evaResult"
  }, {
    path: "/pageB/evaResultList",
    component: _7665a89e,
    name: "pageB-evaResultList"
  }, {
    path: "/pageB/filling",
    component: _3d8fce12,
    name: "pageB-filling"
  }, {
    path: "/pageB/industry",
    component: _6b09615b,
    name: "pageB-industry"
  }, {
    path: "/pageB/industryDetail",
    component: _23d931e8,
    name: "pageB-industryDetail"
  }, {
    path: "/pageB/luquRule",
    component: _7cbb07f4,
    name: "pageB-luquRule"
  }, {
    path: "/pageB/majorGoodSchool",
    component: _37af4b3d,
    name: "pageB-majorGoodSchool"
  }, {
    path: "/pageB/majorTool",
    component: _5a2e5c78,
    name: "pageB-majorTool"
  }, {
    path: "/pageB/organCompare",
    component: _770850de,
    name: "pageB-organCompare"
  }, {
    path: "/pageB/organPage",
    component: _34d17b93,
    name: "pageB-organPage"
  }, {
    path: "/pageB/planPage",
    component: _57a177d5,
    name: "pageB-planPage"
  }, {
    path: "/pageB/postSchool",
    component: _95eef11e,
    name: "pageB-postSchool"
  }, {
    path: "/pageB/postSchoolPoint",
    component: _77ffaa62,
    name: "pageB-postSchoolPoint"
  }, {
    path: "/pageB/powerPage",
    component: _7cc23e87,
    name: "pageB-powerPage"
  }, {
    path: "/pageB/powerResult",
    component: _25537515,
    name: "pageB-powerResult"
  }, {
    path: "/pageB/provinceRule",
    component: _1116476e,
    name: "pageB-provinceRule"
  }, {
    path: "/pageB/rule",
    component: _63a2b1f9,
    name: "pageB-rule"
  }, {
    path: "/pageB/studioDetail",
    component: _7b0cfe44,
    name: "pageB-studioDetail"
  }, {
    path: "/pageB/studioList",
    component: _230be2d1,
    name: "pageB-studioList"
  }, {
    path: "/pageB/volunChange",
    component: _693cffe2,
    name: "pageB-volunChange"
  }, {
    path: "/rank/alumni-association",
    component: _555b0f78,
    name: "rank-alumni-association"
  }, {
    path: "/rank/comprehensive",
    component: _0c97fb9c,
    name: "rank-comprehensive"
  }, {
    path: "/rank/default-rank",
    component: _5d488aca,
    name: "rank-default-rank"
  }, {
    path: "/school/contrast",
    component: _3a41c18c,
    name: "school-contrast"
  }, {
    path: "/school/contrastList",
    component: _5360bd4a,
    name: "school-contrastList"
  }, {
    path: "/school/contrastResult",
    component: _05440849,
    name: "school-contrastResult"
  }, {
    path: "/school/detail",
    component: _be8fc84a,
    name: "school-detail"
  }, {
    path: "/school/examination",
    component: _c0c24852,
    name: "school-examination"
  }, {
    path: "/school/forecast",
    component: _5c485405,
    name: "school-forecast"
  }, {
    path: "/school/guarantee",
    component: _0a143e6e,
    name: "school-guarantee"
  }, {
    path: "/school/list",
    component: _f1810bb0,
    name: "school-list"
  }, {
    path: "/school/recommendDetail",
    component: _3e75e9da,
    name: "school-recommendDetail"
  }, {
    path: "/school/recommendDetailInfo",
    component: _7227d13e,
    name: "school-recommendDetailInfo"
  }, {
    path: "/school/recommendList",
    component: _b4a25140,
    name: "school-recommendList"
  }, {
    path: "/shop/address",
    component: _beab17c8,
    name: "shop-address"
  }, {
    path: "/shop/address_info",
    component: _0bcbd2a1,
    name: "shop-address_info"
  }, {
    path: "/shop/create_order",
    component: _5427245a,
    name: "shop-create_order"
  }, {
    path: "/shop/goods_info",
    component: _6c87617f,
    name: "shop-goods_info"
  }, {
    path: "/shop/goods_list",
    component: _9bbd9522,
    name: "shop-goods_list"
  }, {
    path: "/shop/order_group",
    component: _2cd78176,
    name: "shop-order_group"
  }, {
    path: "/shop/order_info",
    component: _da102ef2,
    name: "shop-order_info"
  }, {
    path: "/shop/order_list",
    component: _4edc8712,
    name: "shop-order_list"
  }, {
    path: "/tools/activationCode",
    component: _01688e18,
    name: "tools-activationCode"
  }, {
    path: "/tools/baogao",
    component: _bce36a8c,
    name: "tools-baogao"
  }, {
    path: "/tools/baogaoList",
    component: _7c6e0110,
    name: "tools-baogaoList"
  }, {
    path: "/tools/batch",
    component: _40f68355,
    name: "tools-batch"
  }, {
    path: "/tools/bindPage",
    component: _02ded7a1,
    name: "tools-bindPage"
  }, {
    path: "/tools/browseLog",
    component: _8d84ba16,
    name: "tools-browseLog"
  }, {
    path: "/tools/coinLog",
    component: _ea5d8c64,
    name: "tools-coinLog"
  }, {
    path: "/tools/collectPlan",
    component: _46751664,
    name: "tools-collectPlan"
  }, {
    path: "/tools/collectSchool",
    component: _3c070f4e,
    name: "tools-collectSchool"
  }, {
    path: "/tools/configValue",
    component: _57eb42aa,
    name: "tools-configValue"
  }, {
    path: "/tools/contactUs",
    component: _0ac6310e,
    name: "tools-contactUs"
  }, {
    path: "/tools/counter",
    component: _9ceb8752,
    name: "tools-counter"
  }, {
    path: "/tools/datas",
    component: _66b0b2a4,
    name: "tools-datas"
  }, {
    path: "/tools/easterEgg",
    component: _23700456,
    name: "tools-easterEgg"
  }, {
    path: "/tools/feedback",
    component: _7ed3fa3a,
    name: "tools-feedback"
  }, {
    path: "/tools/general",
    component: _54809943,
    name: "tools-general"
  }, {
    path: "/tools/generalDetail",
    component: _09ab10f4,
    name: "tools-generalDetail"
  }, {
    path: "/tools/inviteLog",
    component: _6a8d5d96,
    name: "tools-inviteLog"
  }, {
    path: "/tools/kf",
    component: _8f7c8a60,
    name: "tools-kf"
  }, {
    path: "/tools/news",
    component: _2dc31908,
    name: "tools-news"
  }, {
    path: "/tools/newsDetail",
    component: _55fde8f9,
    name: "tools-newsDetail"
  }, {
    path: "/tools/province",
    component: _4cd72736,
    name: "tools-province"
  }, {
    path: "/tools/rank",
    component: _2c3af43e,
    name: "tools-rank"
  }, {
    path: "/tools/rankDetail",
    component: _37048f12,
    name: "tools-rankDetail"
  }, {
    path: "/tools/rankList",
    component: _01ad4f1f,
    name: "tools-rankList"
  }, {
    path: "/tools/signUp",
    component: _c92f24a6,
    name: "tools-signUp"
  }, {
    path: "/tools/subsection",
    component: _0c6a841a,
    name: "tools-subsection"
  }, {
    path: "/tools/subsection_bak",
    component: _a943a032,
    name: "tools-subsection_bak"
  }, {
    path: "/tools/subsectionDetail",
    component: _4e593e8b,
    name: "tools-subsectionDetail"
  }, {
    path: "/tools/video",
    component: _6a7d8fd4,
    name: "tools-video"
  }, {
    path: "/tools/vip",
    component: _c30be110,
    name: "tools-vip"
  }, {
    path: "/tools/vip%20copy",
    component: _642fa266,
    name: "tools-vip copy"
  }, {
    path: "/tools/vip2",
    component: _30df88ea,
    name: "tools-vip2"
  }, {
    path: "/user/addSchoolExam",
    component: _919fc21e,
    name: "user-addSchoolExam"
  }, {
    path: "/user/edit",
    component: _63e784dd,
    name: "user-edit"
  }, {
    path: "/user/editData",
    component: _055f6db2,
    name: "user-editData"
  }, {
    path: "/user/editProvince",
    component: _aa2451a6,
    name: "user-editProvince"
  }, {
    path: "/user/editScore",
    component: _43e748e5,
    name: "user-editScore"
  }, {
    path: "/user/schoolExam",
    component: _5c42a074,
    name: "user-schoolExam"
  }, {
    path: "/school/components/SchoolInfo",
    component: _8d59373e,
    name: "school-components-SchoolInfo"
  }, {
    path: "/school/components/TongKao",
    component: _7c960694,
    name: "school-components-TongKao"
  }, {
    path: "/school/components/XiaoKao",
    component: _131ee4eb,
    name: "school-components-XiaoKao"
  }, {
    path: "/",
    component: _08f26561,
    name: "index"
  }, {
    path: "/generalinfo/:id/",
    component: _09ab10f4,
    name: "generalinfo"
  }, {
    path: "/information/:id/",
    component: _55fde8f9,
    name: "information"
  }, {
    path: "/informationlist/:type/",
    component: _2dc31908,
    name: "informationlist"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
