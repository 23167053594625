
import { Toast, Dialog } from "vant";
import { mapState, mapMutations } from "vuex";
import SplashScreen from '@/components/SplashScreen.vue';
export default {
  components: {
    SplashScreen
  },
  computed: { 
    ...mapState([
      "vuex_device",
      "vuex_include_arr",
      "vuex_system",
      "vuex_status_bar_height",
      "vuex_geetest_appid",
      "vuex_app_serve",
    ]),
    status_bar_height: function () {
      if (process.client) {
        return this.vuex_status_bar_height || 0;
      }
    },
  },
  data() {
    return {
      showAd:['/school/list','/tools/subsection','/tools/batch','/major/'],
      loadingPage: false, //是否显示广告
      showFullNav: true, //是否显示底部导航动画
      scrollPosition: 0, //滚动轴位置
      serveShow: false,
      isApp: false,
      active: 0,
      safeArea: {},
      showMenu: false,
      menu: [
        {
          url: "/",
          icon: "home-o",
          name: "首页",
        },
        // {
        //   url: "/filling/down",
        //   icon: "completed",
        //   name: "Ai志愿表",
        // },
        // {
        //   url: "/tools/video",
        //   icon: "video-o",
        //   name: "视频",
        // },
        {
          url: "/user",
          icon: "user-o",
          name: "我的",
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true, //加上此配置之后，watch即可以在首次进入或刷新之后执行handler （），即初始化即可执行监听
      handler(to, from) {
        //监听之后执行的回调
        this.act_url = to.path;
        this.inMenu();
        // if (to.path === '/login-pc' && this.allConfig.enable_login !== '1') {    //判断条件
        //   this.$router.push('/pc')
        // }
        // if (
        //   to.path === '/pc/register' &&
        //   this.allConfig.enable_register !== '1'
        // ) {
        //   this.$router.push('/pc')
        // }
      },
    },
  },
  // async asyncData({ app, query }) {
  //   let urltoken = query.token;
  //   if (urltoken) {
  //     app.$cookies.set("token", urltoken);
  //   }
  //   let token = app.$cookies.get("token");
  //   console.log(query);
  //   if (token) {
  //     // 获取用户信息
  //     await this.$store.dispatch("getUserInfo").then((res) => {
  //       console.warn('获取用户信息');
  //     });
  //   }
  // },
  async created() {
    // if (process.server) {
    this.$store.dispatch("getConfig"); // 获取通用配置
    // }
    if (process.client && window && window.navigator) {
      // if(this.showAd.indexOf(this.$route.path) != -1){
      //   this.loadingPage = true;
      //   setTimeout(() => {
      //     this.loadingPage = false;  
      //   }, 3000);
      // }else{
      //   this.loadingPage = false;
      // }
      let _this = this;
      // 绑定渠道及oaid
      this.$bus.$on("bindVendorOaid", (data) => {
        // alert(JSON.stringify(data));
        if(data.vendor){
          this.$cookies.set("vendor", data.vendor);
        }
        let token = localStorage.getItem("token");
        if (token && data.oaid) {
          let params = {
            oaid: data.oaid,
            vendor: data.vendor,
            token: token
          }
          this.$axios.$post("/app/artapi.php?r=v2.user.bind_vendor_oaid", params).then(res => {
          })
        }
      })
      // let urlcode = this.$route.query.code;
      // let urltoken = this.$route.query.token;
      // let version_code = this.$route.query.version_code;
      // let system = this.$route.query.system;
      // if(version_code){
      //   localStorage.setItem("token", urltoken);
      //   this.$cookies.set("token", urltoken);
      // }
      // if(system){
      //   localStorage.setItem("system", system);
      //   this.$cookies.set("system", system);
      // }
      // this.act_url = this.$nuxt.$route.path;
      // if (urltoken) {
      //   localStorage.setItem("token", urltoken);
      //   this.$cookies.set("token", urltoken);
      // }
      // 提取公共逻辑为单独函数
      function setTokenToStorage(token, storageType) {
        localStorage.setItem(storageType, token);
        this.$cookies.set(storageType, token);
      }

      let urlcode = this.$route.query.code;
      let urltoken = this.$route.query.token;
      let version_code = this.$route.query.version_code;
      console.log(version_code);
      let system = this.$route.query.system;

      // 设置version_code
      if (version_code) {
        setTokenToStorage.call(this, urltoken, "token");
        setTokenToStorage.call(this, version_code, "version_code");
        this.act_url = this.$nuxt.$route.path;
      }

      // 设置system
      if (system) {
        setTokenToStorage.call(this, system, "system");
        this.act_url = this.$nuxt.$route.path;
      }

      // 设置token
      if (urltoken && urltoken !== "undefined" && urltoken != 'error') {
        setTokenToStorage.call(this, urltoken, "token");
      }
      let token = this.$cookies.get("token");
      if (token && token !== "undefined" && token != 'error') {
        this.$store.dispatch("getUserInfo"); // 获取用户信息
      }
      this.inMenu();
      // let nettype = this.getNetworkType();
      // if (nettype === 'wifi') {
      //     this.set_h5_pre_login(false);
      // } else {
      //     this.set_h5_pre_login(true);
      // }
      let ua = window.navigator.userAgent.toLowerCase();
      console.log(ua);
      // 检查UA中是否包含特定关键词
      if (
        ua.indexOf("ralfqbk_apicloud") > 0 ||
        ua.indexOf("uni-app") > 0 ||
        ua.indexOf("html5plus") > 0
      ) {
        // 如果包含uni-app或者html5plus关键词
        if (ua.indexOf("uni-app") > 0 || ua.indexOf("html5plus") > 0) {
          this.set_app_serve("uniapp"); // 设置打包渠道为uniapp
        }
        // 如果包含ralfqbk_apicloud-app关键词
        if (ua.indexOf("ralfqbk_apicloud-app") > 0) {
          this.set_app_serve("apicloud"); // 设置打包渠道为apicloud
        }

        this.isApp = true; // 标记为在App环境中
        // 根据设备类型设置系统类型
        if (ua.match(/(iPhone|iPad|iPod|iOS)/i)) {
          this.set_system("ios"); // 设置系统类型为iOS
        } else if (ua.match(/(Android)/i)) {
          this.set_system("android"); // 设置系统类型为Android
        }
      } else {
        this.isApp = false; // 不在App环境中
        this.set_app_serve(""); // 设置打包渠道为uniapp
      }

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.set_system("WXMEB"); // 微信浏览器
      }
      if (/miniprogram/i.test(ua)) {
        // 微信小程序
        this.set_system("WXAPP");
      }
      if (/aliapp/i.test(ua)) {
        // 支付宝小程序
        this.set_show_general(false);
        this.set_system("ALIAPP");
        let platform = this.$route.query.platform;
        if (platform != "android") {
          this.set_show_buy(true); // 是否显示购买
        } else {
          this.set_show_buy(true);
        }
      }
      if (/toutiaomicroapp/i.test(ua)) {
        // 抖音小程序
        this.set_show_general(false);
        this.set_system("DOUYINAPP");
        let platform = this.$route.query.platform;
        let mpVersion = this.$route.query.mpVersion;
        // this.set_show_buy(false);return;
        // if (mpVersion == '0.0.1') {
        //   this.set_show_buy(false);
        // } else {
        this.set_show_buy(true);
        // if (platform != "android") {
        //   this.set_show_buy(false);
        // } else {
        //   this.set_show_buy(true);
        // }
        // }
      }
      if (/baiduboxapp/i.test(ua) && /swan/i.test(ua)) {
        // 百度小程序
        this.set_show_general(false);
        this.set_system("BAIDUAPP");
        let platform = this.$route.query.platform;
        if (platform != "android") {
          this.set_show_buy(true);
        } else {
          this.set_show_buy(true);
        }
      }
      if (urlcode) {
        await this.codeToOpenId(urlcode);
      }
      // 用户身份判断
      if (this.isApp) {
        let that = this;
        that.$cookies.set("isApp", true);
        // window.uni.postMessage({
        //   data: {
        //     name: "loaded",
        //   },
        // });

        return;

        window.apiready = function () {
          var deviceModel = api.deviceModel;
          var deviceName = api.deviceName;
          // alert(deviceModel);
          // alert(deviceName);
          // _this.set_device(api.deviceName);
          _this.$cookies.set("vendor", api.deviceName);
          _this.set_system(api.systemType);
          if (_this.vuex_device == "HUAWEI") {
            _this.menu = [
              {
                url: "/",
                icon: "home-o",
                name: "首页",
              },
              {
                url: "/user",
                icon: "user-o",
                name: "我的",
              },
            ];
          }
          api.addEventListener(
            {
              name: "back",
            },
            function (ret, err) {
              if (_this.showMenu) {
                _this.exitApp();
              } else {
                _this.$router.back();
              }
            }
          );
          // 只有ios支持左右轻划返回
          if (api.systemType == "ios") {
            api.addEventListener(
              {
                name: "swipeleft",
              },
              function (ret, err) {
                _this.goback();
              }
            );
            api.addEventListener(
              {
                name: "swiperight",
              },
              function (ret, err) {
                _this.goback();
              }
            );
          }
          api.setStatusBarStyle({
            style: "light",
          });
          // if (this.vuex_system == 'ios' || this.vuex_system == 'android') {
          //   $api.setStorage('token', res.data.token);
          // }
          token = api.getPrefs({
            sync: true,
            key: "token",
          });
          if (token) {
            _this.$cookies.set("token", token);
            _this.$store.dispatch("getUserInfo"); // 获取用户信息
          }
          _this.acInit(); // 相关模块初始化
        };
      } else {
        // this.$cookies.set("isApp", false);
        // this.set_device('NOHUAWEI');
        // token = this.$cookies.get("token");
        // if (token || urltoken) {
        //   token = urltoken ?? token;
        //   this.$cookies.set("token", token);
        //   this.$store.dispatch("getUserInfo"); // 获取用户信息
        // }
      }
      // 监控滚动轴
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    ...mapMutations([
      "set_device",
      "set_system",
      "set_pre_login",
      "set_status_bar_height",
      "set_installed_wx",
      "set_h5_pre_login",
      "set_show_buy",
      "set_show_general",
      "set_app_serve",
    ]),
    splashHide(){
      this.loadingPage = false;  
    },
    handleScroll() {
      if (process.client) {
        var currentScrollPosition = window.scrollY;
        if (currentScrollPosition < this.scrollPosition) {
          this.showFullNav = true;
        } else {
          this.showFullNav = false;
        }
        this.scrollPosition = window.scrollY;
      }
    },
    goback() {
      let unBackUrls = [
        "/",
        "/filling/",
        "/user",
        "/user/",
        "/filling",
        "/filling/table",
        "/major/",
        "/major/detail",
        "/school/contrastResult",
      ];
      if (unBackUrls.indexOf(this.$nuxt.$route.path) == -1) {
        this.$router.back();
      }
    },

    exitApp(flag = true) {
      //安卓双击退出
      if (window.__start == "undefined" || !window.__start) {
        window.__start = new Date().getTime();
        api.toast({
          msg: "再按一次返回键退出",
          duration: 1500,
          location: "bottom",
        });
      } else {
        if (new Date().getTime() - window.__start < 1500) {
          flag ? (flag = flag) : (flag = true);
          switch (flag) {
            case true:
              //APP进入后台运行
              api.toLauncher();
              break;
            case false:
              //直接退出APP（会杀死进程）
              api.closeWidget({
                id: appID,
                retData: {
                  name: "closeWidget",
                },
                animation: {
                  type: "flip",
                  subType: "from_bottom",
                  duration: 500,
                },
                silent: true,
              });
              break;
            default:
          }
        } else {
          window.__start = new Date().getTime();
          api.toast({
            msg: "再按一次返回键退出",
            duration: 1500,
            location: "bottom",
          });
        }
      }
    },
    // 微信公众号换code
    async codeToOpenId(code) {
      return new Promise((resolve, reject) => {
        let url = "/app/artapi.php?r=ralfqbk.wemeb_login";
        let params = {
          code: code,
          login_type: 2,
        };
        this.$axios.$post(url, params).then((res) => {
          if (res.code == 200) {
            let { openid, token } = res.data;
            if (token) {
              this.$cookies.set("token", token);
            }
            if (openid) {
              this.$cookies.set("openid", openid);
            }
            resolve(res);
          } else {
            resolve(res);
          }
        });
      });
    },
    // 相关模块初始化
    acInit() {
      let _this = this;
      // let ACGeetest = api.require("ACGeetest");
      this.set_pre_login(false);
      let wxPlus = api.require("wxPlus");
      let geetAppid = this.vuex_geetest_appid;
      wxPlus.isInstalled((ret, err) => {
        this.set_installed_wx(ret.installed);
      });
      // ACGeetest.register({
      //   appID: geetAppid
      // });
      // ACGeetest.isPreGetTokenResultValidate((ret) => {
      //   this.set_pre_login(ret.isPreGet);
      // });
      if (this.vuex_system == "ios") {
        let iap = api.require("iap");
        iap.setTransactionListener(
          {
            autoFinishTransaction: false,
          },
          function (ret, err) {
            if (ret && ret.errorMsg) {
              Toast.clear();
              Toast(ret.errorMsg);
            }
            if (ret && ret.state == 1) {
              Toast.clear();
              // alert('s');
              // ret.transactionId
              let params = ret;
              _this.$axios
                .$post("/app/artapi.php?r=ralfqbk.applepay", params)
                .then((data) => {
                  if (data.code == 200) {
                    Dialog.alert({
                      message: "支付成功",
                    }).then(() => {
                      _this.$router.replace({
                        path: "/user",
                      });
                    });
                  }
                });
              // alert(JSON.stringify(ret));
            }
          }
        );
      }
    },
    // 初始化菜单
    inMenu() {
      let showMenu = false;
      let active = 0;
      this.menu.forEach((item, index) => {
        if (this.act_url == item.url) {
          showMenu = true;
          active = index;
        }
      });
      this.active = active;
      this.showMenu = showMenu;
    },
    // 返回上一级方法
    backBefore() {
      let notBackArr = ["/"];
      if (notBackArr.indexOf(this.$route.path) == -1) {
        this.$router.back();
      }
    },
  },
};
