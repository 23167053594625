import Vue from 'vue'
import Vuex from 'vuex'
import data from '~/assets/js/data.js'
Vue.use(Vuex)
export const state = () => ({
  vuex_env_available: false, //是否支持一键登录
  vuex_individualization: true, //个性化推荐
  vuex_province_mode: '',
  vuex_include_arr: ["examIndex", 'majorList', 'schoolList', 'major', 'comprehensive', 'alumniAssociation', 'general', 'fillFilling', 'schoolExamination', "researchList", "rankList", 'fillTableInfo'],
  token: '',
  vuex_app_serve: 'uniapp', //是用什么平台打包 uniapp或apicloud
  vuex_device: 'NOHUAWEI', //设备
  vuex_buy_tips: '解锁全部信息',
  vuex_config_top_menu: data.config.top_menu,
  vuex_config: {}, //配置
  vuex_status_bar_height: 0, //状态栏高度
  vuex_system: 'H5', //系统类型
  vuex_show_general: true, //是否显示简章
  vuex_show_buy: true, //是否显示购买
  vuex_examine: false, //是否考试
  vuex_geetest_appid: '692db42642377f976123797cb5c06210', // 极验appid
  vuex_geetest_h5_appid: '56e263169efc7c091479c8ef7fdf4b0a', // 极验h5appid
  vuex_pre_login: false, //是否可以使用一键登录
  vuex_h5_pre_login: false, //是否可以使用h5一键登录
  vuex_installed_wx: false, // 是否安装了微信
  vuex_user_info: {},
  vuex_provinces_list: data.provinceList, //省份列表
  vuex_art_type: [], //一级类
  vuex_subject_arr: [], //选科列表
  vuex_province_id: '', //省份id
  vuex_province_name: '', //省份名称
  vuex_report_info: {}, //志愿表详情信息
  vuex_batch_name: '', //志愿表批次名称
  vuex_report_detail: {},
  vuex_table_major_planids: [], // 志愿表选中的专业列表
  vuex_table_school_planids: [], //志愿表选中的院校列表
  vuex_table_group_codes: [],
  vuex_table_college_form_codes: [],
  vuex_show_ios_pay: '',
  vuex_non_empty_plan_ids: [], // 已选中的计划ids
  vuex_change_school: {} // 对应院校修改专业列表
})
// 修改state里面的函数必须用到mutations里面的函数
export const mutations = {
  SET_ENV_AVAILABLE(state, result) {
    state.vuex_env_available = result;
  },
  // 设置被选中的计划ids
  set_non_empty_plan_ids(state, data) {
    state.vuex_non_empty_plan_ids = data;
  },
  set_change_school(state, data) {
    state.vuex_change_school = data;
  },
  // 设置个性化推荐
  set_individualization(state, data) {
    state.vuex_individualization = data;
    this.$cookies.set('vuex_individualization', data);
  },
  set_app_serve(state, data) {
    state.vuex_app_serve = data;
  },
  set_token(state, data) {
    state.token = data;
  },
  set_show_general(state, data) {
    state.vuex_show_general = data;
  },
  set_show_buy(state, data) {
    state.vuex_show_buy = data;
  },
  set_status_bar_height(state, data) {
    state.vuex_status_bar_height = data;
  },
  set_pre_login(state, data) {
    state.vuex_pre_login = data;
  },
  set_h5_pre_login(state, data) {
    state.vuex_h5_pre_login = data;
  },
  set_installed_wx(state, data) {
    state.vuex_installed_wx = data;
  },
  // 设置系统类型
  set_system(state, data) {
    state.vuex_system = data;
  },
  set_device(state, data) {
    state.vuex_device = data;
  },
  set_include_arr(state, data) {
    state.vuex_include_arr = data;
  },
  set_user_info(state, data) {
    state.token = data.token
    state.vuex_user_info = data
    state.vuex_province_id = data.province_id
    this.$cookies.set('token', data.token);
    this.$cookies.set('vuex_user_info', data);
  },
  set_table_group_codes(state, data) {
    state.vuex_table_group_codes = data;
  },
  set_table_college_form_codes(state, data) {
    state.vuex_table_college_form_codes = data;
  },
  set_table_school_planids(state, data) {
    state.vuex_table_school_planids = data;
  },
  set_table_major_planids(state, data) {
    state.vuex_table_major_planids = data;
  },
  set_report_detail(state, data) {
    state.vuex_report_detail = data;
  },
  set_batch_name(state, data) {
    state.vuex_batch_name = data;
  },
  set_provinces_list(state, data) {
    state.vuex_provinces_list = data
  },
  set_province_mode(state, data) {
    state.vuex_province_mode = data
  },
  set_art_type(state, data) {
    state.vuex_art_type = data
  },
  set_subject_arr(state, data) {
    state.vuex_subject_arr = data
  },
  set_province_id(state, data) {
    state.vuex_province_id = data
  },
  set_province_name(state, data) {
    state.vuex_province_name = data
  },
  set_config(state, data) {
    state.vuex_config = data
  },
  set_report_info(state, data) {
    state.vuex_report_info = data
  }
}
export const actions = {
  // 服务初始化 持久化
  nuxtServerInit({
    commit,
    dispatch
  }, {
    req
  }) {
    let token = this.$cookies.get('token');
    // 个性化推荐的缓存更新
    let vuex_individualization = this.$cookies.get('vuex_individualization');
    if (vuex_individualization == false) {
      commit('set_individualization', false);
    } else {
      commit('set_individualization', true);
    }
    // let vuex_config = this.$cookies.get('vuex_config');
    // if (vuex_config) {
    //   commit('set_config', params)
    // }
    if (token) {
      commit('set_token', token);
    }
    // if (vuex_config) {
    //   commit('set_config', vuex_config);
    // }
  },
  async setEnvAvailable({
    commit
  }, result) {
    commit('SET_ENV_AVAILABLE', result);
  },
  // 获取用户信息
  getUserInfo({
    dispatch,
    commit
  }, val) {
    return new Promise((resolve, reject) => {
      this.$axios.get("/app/artapi.php?r=user.get_user_info")
        .then(({
          data
        }) => {
          if (data.code == 200) {
            commit('set_user_info', data.data);
            if (process.client) {
              localStorage.setItem('token', data.data.token);
              localStorage.setItem('user_info', JSON.stringify(data.data));
              // if (!data.data.vendor) {
                window.uni.postMessage({
                  data: {
                    name: "evalJs",
                    value: `
                    let brand = uni.getSystemInfoSync().brand;
                    if(brand){
                      let pages = getCurrentPages();
                      let page = pages[pages.length - 1];
                      let currentWebview = page.$getAppWebview();
                      let wv = currentWebview.children()[0];
                      wv.evalJS("bind_vendor_oaid({vendor: '" + brand + "'})");
                    }
                    plus.device.getOAID({
                      success: function(e) {
                      if (e.oaid) {
                        let pages = getCurrentPages();
                        let page = pages[pages.length - 1];
                        let currentWebview = page.$getAppWebview();
                        let wv = currentWebview.children()[0];
                        wv.evalJS("bind_vendor_oaid({vendor: '" + brand + "',oaid: '" + e.oaid + "'})");
                      }
                      }
                    });`
                  }
                });
              // }
            }
            resolve({
              ...data.data
            });
          }
        })
    })
  },
  // 微信公众号获取用户信息
  WXMEBOath({
    dispatch,
    commit
  }, val) {
    return new Promise((resolve, reject) => {
      let url = this.$cookies.get('href');
      url = url ? url : 'https://m.yikaotianbao.cn';
      url = 'https://m.yikaotianbao.cn';
      let params = {
        url
      }
      this.$axios.get("/app/artapi.php?r=ralfqbk.get_authurl", {
          params
        })
        .then(({
          data
        }) => {
          if (data.code == 200) {
            resolve({
              ...data.data
            });
            window.location.href = data.data.url;
          }
        })
    })
  },
  // 获取志愿表详情
  getReportDetailList({
    dispatch,
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      let url = "/app/artapi.php?r=newquery.get_report_detail_list";
      // if(params.xiaokao == 1){
      //   url = '/app/artapi.php?r=newquery.xk_recommend_list';
      // }
      this.$axios
        .$post(url, params)
        .then((res) => {
          resolve(res);
          if (res.code == 200) {
            let detail = res.data;
            this.school_list = detail;
            if (detail.school_list && detail.school_list.length >= 1) {
              let vuex_table_school_planids = [];
              let vuex_table_group_codes = [];
              let vuex_table_college_form_codes = [];
              detail.school_list.map((item) => {
                if (item.plan_id && item.plan_id != 0) {
                  vuex_table_school_planids.push(item.plan_id + "");
                } else {
                  vuex_table_school_planids.push("-1");
                }
                if (item.group_code && item.group_code != 0) {
                  vuex_table_group_codes.push(item.group_code + "");
                } else {
                  vuex_table_group_codes.push("-1");
                }
                if (item.college_form_code && item.college_form_code != 0) {
                  vuex_table_college_form_codes.push(item.college_form_code + "");
                } else {
                  vuex_table_college_form_codes.push("-1");
                }
              });
              commit(
                "set_table_school_planids",
                vuex_table_school_planids
              );
              commit(
                "set_table_group_codes",
                vuex_table_group_codes
              );
              commit(
                "set_table_college_form_codes",
                vuex_table_college_form_codes
              );
            } else {
              detail["school_list"] = [];
            }
            if (detail.major_list && detail.major_list.length >= 1) {
              let vuex_table_major_planids = [];
              detail.major_list.map((item) => {
                if (item.plan_id) {
                  vuex_table_major_planids.push(item.plan_id + "");
                } else {
                  vuex_table_major_planids.push("-1");
                }
              });
              commit(
                "set_table_major_planids",
                vuex_table_major_planids
              );
            }
            commit("set_report_detail", detail);
          }
        });
    })
  },
  // 获取通用配置
  getConfig({
    dispatch,
    commit
  }, val) {
    return new Promise((resolve, reject) => {
      this.$axios.get("/app/artapi.php?r=getconfig.get_config")
        .then(({
          data
        }) => {
          if (data.code == 200) {
            commit('set_config', data.data);
          }
        })
    })
  },
  getReportInfo({
    dispatch,
    commit
  }, val) {
    return new Promise((resolve, reject) => {
      console.log(val)
      this.$axios.$post("/app/artapi.php?r=newquery.get_report_info", {
        id: val
      }).then((data) => {
        if (data.code == 200) {
          commit('set_report_info', data.data);
        }
      })
    })
  },
}


export default {
  state,
  mutations,
  actions
};
