import Vue from 'vue'
export default ({
  app,
  $axios,
  store,
  route,
  redirect
}) => {
  var ralfqbk = {
    install(Vue) {
      Vue.prototype.ralfqbk = {
        api:function(){
          return window.api;
        },
        // 回到顶部
        customScrollTop:function(){
          window.scrollTo(0, 0);
        },
        safeAreaTop:0,
        back: function () {
          window.history.go(-1);
        }
      };

    }
  }
  Vue.use(ralfqbk);
}
