/**
 *   未知路由重定向 404页面
 */
export default ({
  store,
  route,
  redirect
}) => {
  if (!route.matched.length) {
    redirect('/404')
  }
  // if (process.client && route.fullPath.indexOf('?') != -1) {
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 3000);
  // }
}
