
export default {
  name: 'SplashScreen',
  data() {
    return {
      // 倒计时
      countDown: 3,
      timer: null,
      showPage: {},
      images: [{
        url: 'https://nuxt-yikao-mobile.oss-cn-hangzhou.aliyuncs.com/ad/1.jpg',
      }, {
        url: 'https://nuxt-yikao-mobile.oss-cn-hangzhou.aliyuncs.com/ad/2.jpg',
      }, {
        url: 'https://nuxt-yikao-mobile.oss-cn-hangzhou.aliyuncs.com/ad/3.jpg',
      }, {
        url: 'https://nuxt-yikao-mobile.oss-cn-hangzhou.aliyuncs.com/ad/4.jpg',
      }]
    }
  },
  methods: {
    clear() {
      clearInterval(this.timer); // 清除定时器
      this.$el.parentNode.removeChild(this.$el);
      this.$emit('hide');
    },
    toUrl(url) {
      if (url) {
        this.$router.push({
          path: url
        });
      }
    }

  },
  created() {
    function getRandomImage(images) {
      if (Array.isArray(images) && images.length > 0) {
        const randomIndex = Math.floor(Math.random() * images.length);
        return images[randomIndex];
      }
      return null; // 或者返回一个默认值
    }

    // 使用示例
    const images = [
      { url: 'https://nuxt-yikao-mobile.oss-cn-hangzhou.aliyuncs.com/ad/1.jpg' },
      { url: 'https://nuxt-yikao-mobile.oss-cn-hangzhou.aliyuncs.com/ad/2.jpg' },
      { url: 'https://nuxt-yikao-mobile.oss-cn-hangzhou.aliyuncs.com/ad/3.jpg' },
      { url: 'https://nuxt-yikao-mobile.oss-cn-hangzhou.aliyuncs.com/ad/4.jpg' }
    ];

    const randomImage = getRandomImage(images);
    this.showPage = randomImage;
  },
  mounted() {
    // 假设3秒后移除开屏
    this.timer = setInterval(() => {
      this.countDown -= 1;
      if (this.countDown === 0) {
        clearInterval(this.timer); // 清除定时器
        this.$el.parentNode.removeChild(this.$el);
      }
    }, 1000);
  }
  // 任何其他必要的逻辑
}
