export const Analyse = () => import('../../components/Analyse.vue' /* webpackChunkName: "components/analyse" */).then(c => wrapFunctional(c.default || c))
export const Box = () => import('../../components/Box.vue' /* webpackChunkName: "components/box" */).then(c => wrapFunctional(c.default || c))
export const Filling = () => import('../../components/Filling.vue' /* webpackChunkName: "components/filling" */).then(c => wrapFunctional(c.default || c))
export const FillingMajor = () => import('../../components/FillingMajor.vue' /* webpackChunkName: "components/filling-major" */).then(c => wrapFunctional(c.default || c))
export const FillingSchool = () => import('../../components/FillingSchool.vue' /* webpackChunkName: "components/filling-school" */).then(c => wrapFunctional(c.default || c))
export const FillingXiaoKao = () => import('../../components/FillingXiaoKao.vue' /* webpackChunkName: "components/filling-xiao-kao" */).then(c => wrapFunctional(c.default || c))
export const HomeMenu = () => import('../../components/HomeMenu.vue' /* webpackChunkName: "components/home-menu" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const PayBtn = () => import('../../components/PayBtn.vue' /* webpackChunkName: "components/pay-btn" */).then(c => wrapFunctional(c.default || c))
export const School = () => import('../../components/School.vue' /* webpackChunkName: "components/school" */).then(c => wrapFunctional(c.default || c))
export const SchoolVolun = () => import('../../components/SchoolVolun.vue' /* webpackChunkName: "components/school-volun" */).then(c => wrapFunctional(c.default || c))
export const SplashScreen = () => import('../../components/SplashScreen.vue' /* webpackChunkName: "components/splash-screen" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const RalfNavBar = () => import('../../components/ralfNavBar.vue' /* webpackChunkName: "components/ralf-nav-bar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
