"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 *   未知路由重定向 404页面
 */
var _default = function _default(_ref) {
  var store = _ref.store,
      route = _ref.route,
      redirect = _ref.redirect;

  if (!route.matched.length) {
    redirect('/404');
  } // if (process.client && route.fullPath.indexOf('?') != -1) {
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 3000);
  // }

};

exports["default"] = _default;