import {
  Dialog
} from "vant";
const wanshanUrl = [
  '/user/editScore',
  '/filling/table',
  '/school/list',
  '/major/detail',
  '/tools/subsection',
  '/tools/batch',
  '/school/detail',
  '/tools/counter',
  '/exam/detail',
  '/exam/record',
  '/filling/catalogue',
  '/school/examination',
  '/tools/datas'
];
const mustLoginUrls = [
  '/user/edit',
  '/tools/activationCode',
  '/tools/browseLog',
  '/tools/feedback',
  '/school/contrastList',
  '/tools/collectSchool', //院校对比500错误
]
import * as uni from "@/assets/js/uni.webview.1.5.4.js";
export default ({
  app,
  store
}) => {
  app.router.beforeEach((to, from, next) => {
    // 兼容uniapp
    if (process.client) {
      // if(to.path == '/tools/vip'){
      //   uni.postMessage({
      //     data:{
      //       name: 'set_title_nav_view',
      //       type: 'transparent'
      //     }
      //   })
      // }else{
      //   uni.postMessage({
      //     data:{
      //       name: 'set_title_nav_view'
      //     }
      //   })
      // }
      // if(to.query.apptype){
      //   localStorage.setItem('apptype',to.query.apptype);
      // }
      // let apptype = localStorage.getItem('apptype');
      // let token = localStorage.getItem('token');

      if (store.state.vuex_system == 'DOUYINAPP' && to.path == '/') {
        window.tt.miniProgram.reLaunch({
          url: '/pages/index/index'
        });
        return;
      }
      if (store.state.vuex_system == 'DOUYINAPP' && to.path == '/user') {
        console.log('回到小程序');
        window.tt.miniProgram.reLaunch({
          url: '/pages/mine/mine'
        });
        return;
      }
    }
    // 路由一致时不跳转 解决重复跳转问题
    if (to.path == from.path && (from.path != '/' || to.path != '/')) {
      return;
    }
    if (to.path == '/tools/vip' && store.state.vuex_device == 'HUAWEI' && store.state.vuex_system == "android") {
      next('/tools/vip2')
    }
    if (process.client) {
      let token = app.$cookies.get('token');
      let user_info = localStorage.getItem('user_info');

      // 路由拦截
      if (false) {
        if (!token && to.path && (wanshanUrl.indexOf(to.path) != -1 || mustLoginUrls.indexOf(to.path) != -1)) {
          next('/login/login');
          return;
        } else if (token && to.path && wanshanUrl.indexOf(to.path) != -1) {
          let art_class_children = user_info.art_class_children;
          let art_class_children_num = 0;
          let showMajorToast = false;
          if (art_class_children) {
            art_class_children.forEach((item, index) => {
              if (item.value) {
                art_class_children_num += 1;
              }
            })
            if (art_class_children_num != art_class_children.length && user_info.art_class_children_ids.length >= 1) {
              showMajorToast = true;
            }
          }
          if (user_info) {
            user_info = JSON.parse(user_info);
            if (!user_info.province_id) {
              Dialog.confirm({
                  title: '提示',
                  message: '请先完善省份信息后再查看',
                  confirmButtonText: '去完善信息',
                  cancelButtonText: '看看其他的',
                })
                .then(() => {
                  next('/user/editProvince?backType=backHome');
                })
                .catch(() => {

                });
              return;
            }
            // if (!user_info.culture_score) {
            //   Dialog.confirm({
            //       title: '提示',
            //       message: '请先完善成绩后再查看',
            //       confirmButtonText: '去完善信息',
            //       cancelButtonText: '看看其他的',
            //     })
            //     .then(() => {
            //       next('/user/editScore?type=culture');
            //     })
            //     .catch(() => {

            //     });
            //     return;
            // }
            // if (showMajorToast) {
            //   Dialog.confirm({
            //       title: '提示',
            //       message: '请先完善专业成绩后再查看',
            //       confirmButtonText: '去完善信息',
            //       cancelButtonText: '看看其他的',
            //     })
            //     .then(() => {
            //       next('/user/editScore?type=culture');
            //     })
            //     .catch(() => {

            //     });
            //     return;
            // } else if (!user_info.major_score && user_info.art_class_children_ids.length == 0) {
            //   Dialog.confirm({
            //       title: '提示',
            //       message: '请先完善成绩后再查看',
            //       confirmButtonText: '去完善信息',
            //       cancelButtonText: '看看其他的',
            //     })
            //     .then(() => {
            //       next('/user/editScore?type=culture');
            //     })
            //     .catch(() => {

            //     });
            //     return;
            // }
          }
        }
      }
      if (to.path == '/login/login') {
        switch (store.state.vuex_system) {
          case 'WXAPP':
            // 微信小程序
            next()
            break;
          case 'ALIAPP':
            // 支付宝小程序
            next()
            break;
          case 'DOUYINAPP':
            // 抖音小程序
            next('/login/phone?type=home')
            break;
          case 'H5':
            // H5
            next('/login/phone?type=home')
            break;
          case 'WXMEB':
            // 微信浏览器
            next()
            break;
          case 'ios':
            // 苹果手机
            if (!store.state.vuex_installed_wx && !store.state.vuex_pre_login) {
              next('/login/phone?type=home')
            } else {
              next('/login/phone?type=home')
            }
            break;
          case 'android':
            // 安卓手机
            if (!store.state.vuex_installed_wx && !store.state.vuex_pre_login) {
              next('/login/phone?type=home')
            } else {
              next('/login/phone?type=home')
            }
            break;
          default:
            next()
            break;
        }
      } else {
        next()
      }
    }
  })
}
