export default ({
  app,
  route,
  store
}) => {
  // 将函数挂载到window对象上
  if (process.client) {
    let that = this;
    window.check_env_available = function(result){
      // store.vuex_env_available = result;
      store.commit('SET_ENV_AVAILABLE', result); // 使用commit调用mutation来改变状态
    }
    window.set_version = function(version){
      if(version){
        localStorage.setItem('version', version);
        app.$cookie.set('version', version);
      }
    }
    window.bind_vendor_oaid = function(code){
      app.$bus.$emit('bindVendorOaid', code);
    }
    // 一键登录完成回调
    window.login_token = function (code) {
      app.$bus.$emit('loginToken', code);
    }
    // 支付完成回调
    window.pay_back = function (code) {
      app.$bus.$emit('payCallBack', code);
    }
    // 微信授权回调
    window.wx_auth_back = function (code) {
      app.$bus.$emit('wxAuthCallBack', code);
    }
    // 苹果授权回调
    window.apple_auth_back = function (code) {
      app.$bus.$emit('appleAuthCallBack', code);
    }
    // app端回调
    window.want_back = function () {
      window.history.back();
      window.uni.postMessage({
        data: {
          name: 'h5_want_back',
          url: route.fullPath
        }
      })
      if (window.history.length > 2) {
        console.log('返回上一级');
        window.history.back();
        window.uni.postMessage({
          name: 'h5_want_back',
          url: route.fullPath
        })
      } else {
        window.uni.postMessage({
          name: 'h5_want_close',
          url: route.fullPath
        })
        console.log('关闭webview');
      }
    }
  }
}

