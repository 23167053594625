import {
  Toast,
  Notify,
  Dialog
} from "vant";

import CryptoJS from 'crypto-js';
let wanshanUrl = [
  // 'major.get_major_school_v2',
  'newquery.create_table',
  // 'query.get_school_exams_batch',
  // 'school.school_list_v2',
  // 'schoo.get_school_major_list',
  // 'school.get_batch_screen',
  // 'school.get_type_groups',
  // 'school.get_subject_groups',
  // 'user.calc_score_sch_list',
];

function removenonprintable(str) {
  str = str.replace(/[\x00-\x09]/, '');
  // str = str.replace(/[\x00-\x03]/, '');
  str = str.replace(/[\x0A-\x0F]/, '');
  str = str.replace(
    /\u0000|\u0001|\u0002|\u0003|\u0004|\u0005|\u0006|\u0007|\u0008|\u0009|\u000a|\u000b|\u000c|\u000d|\u000e|\u000f|\u0010|\u0011|\u0012|\u0013|\u0014|\u0015|\u0016|\u0017|\u0018|\u0019|\u001a|\u001b|\u001c|\u001d|\u001e|\u001f|\u007F/g,
    "");
  return str;
}

function ralfqbk(response) {
  if (response.data && response.data.type && response.data.type == 'AES-128-ECB') {
    let key = 'xzy58yni6qHQ170y';
    let iv = 'L17eIWVmFHpTWgTB';
    let str = response.data.data;
    key = CryptoJS.enc.Utf8.parse(key); // 秘钥
    iv = CryptoJS.enc.Utf8.parse(iv); //向量iv
    var decrypted = CryptoJS.AES.decrypt(str, key, {
      iv: iv,
      padding: CryptoJS.pad.ZeroPadding
    });
    decrypted = removenonprintable(decrypted.toString(CryptoJS.enc.Utf8));
    decrypted = decrypted.replace(/\s*/g, "");
    response.data.data = JSON.parse(decrypted);
  }
  return response;
}

export default ({
  $axios,
  store,
  route,
  redirect,
  app
}) => {
  //Request拦截器，设置token
  $axios.onRequest(config => {
    // let _this = this;
    // let urlcode = this.$route.query.code;
    // let urltoken = this.$route.query.token;
    // this.act_url = this.$nuxt.$route.path;
    // if (urltoken) {
    //   localStorage.setItem('token', urltoken);
    //   this.$cookies.set("token", urltoken);
    // }
    // let token = this.$cookies.get("token");
    // if (token) {
    //   this.$store.dispatch("getUserInfo"); // 获取用户信息
    // }
    return new Promise((resolve, reject) => {
      let token = app.$cookies.get('token');
      let version = app.$cookies.get('version');
      if (!token) {
        token = route.query.token;
        app.$cookies.set('token', token);
      }
      if (!version) {
        version = route.query.version;
        app.$cookies.set('version', version);
      }
      if (process.client) {
        token = localStorage.getItem('token') && localStorage.getItem('token') != 'error' ? localStorage.getItem('token') : app.$cookies.get('token');
        version = localStorage.getItem('version') ? localStorage.getItem('version') : app.$cookies.get('version');
      }
      let vendor = app.$cookies.get('vendor');
      let url = config.url.replace('/app/artapi.php?r=', '');
      config.headers.requestType = 2;
      // config.headers.loginmodel = 'mobile';
      config.headers.agentid = 9;
      config.url = config.url + '&i=26';
      // console.log(config.url);
      if (token && token != 'error') {
        config.headers.token = token;
        if (vendor) {
          config.headers.vendor = vendor;
        }
        if (version) {
          config.headers.version = version;
        }
        if (process.client) {
          let user_info = localStorage.getItem('user_info');
          if (user_info && url && wanshanUrl.indexOf(url) != -1) {
            user_info = JSON.parse(user_info);
            // Toast.clear();
            let art_class_children = user_info.art_class_children;
            let art_class_children_num = 0;
            let showMajorToast = false;
            if (art_class_children) {
              art_class_children.forEach((item, index) => {
                if (item.value) {
                  art_class_children_num += 1;
                }
              })
              if (art_class_children_num != art_class_children.length && user_info.art_class_children_ids.length >= 1) {
                showMajorToast = true;
              }
            }
            if (user_info && false) {
              if (!user_info.province_id) {
                Toast.clear();
                Dialog.confirm({
                    title: '提示',
                    message: '请先完善省份信息后再查看',
                    confirmButtonText: '去完善信息',
                    cancelButtonText: '看看其他的',
                  })
                  .then(() => {
                    app.router.replace('/user/editProvince?backType=backHome');
                  })
                  .catch(() => {});
                reject('拦截了');
              }
              if (!user_info.culture_score) {
                Toast.clear();
                Dialog.confirm({
                    title: '提示',
                    message: '请先完善成绩后再查看',
                    confirmButtonText: '去完善信息',
                    cancelButtonText: '看看其他的',
                  })
                  .then(() => {
                    app.router.replace('/user/editScore?type=culture');
                  })
                  .catch(() => {
                    // app.router.replace('/');
                  });
                reject('拦截了');
              }
              if (showMajorToast) {
                Toast.clear();
                Dialog.confirm({
                    title: '提示',
                    message: '请先完善专业成绩后再查看',
                    confirmButtonText: '去完善信息',
                    cancelButtonText: '看看其他的',
                  })
                  .then(() => {
                    app.router.replace('/user/editScore?type=culture');
                  })
                  .catch(() => {
                    // app.router.replace('/');
                  });
                reject('拦截了1');
              } else if (!user_info.major_score && user_info.art_class_children_ids.length == 0) {
                Toast.clear();
                Dialog.confirm({
                    title: '提示',
                    message: '请先完善成绩后再查看',
                    confirmButtonText: '去完善信息',
                    cancelButtonText: '看看其他的',
                  })
                  .then(() => {
                    app.router.replace('/user/editScore?type=culture');
                  })
                  .catch(() => {
                    // app.router.replace('/');
                  });
                reject('拦截了2');
              }
            }
          }

        }
        resolve(config);
      } else {
        config.headers.token = '';
        resolve(config);
      }
    })
    //Error拦截器 : 出现错误的时候被调用
    $axios.onRequest((error) => {
      console.log(222);
    })
  })
  $axios.onResponse((response) => {
    response = ralfqbk(response);
    if (response.data.success == false) {
      return;
    }
    switch (response.data.code) {
      case 201:
        Toast.success("注册成功,请前去完善基础信息!");
        let {
          openid, token
        } = response.data.data;
        if (token) {
          app.$cookies.set('token', token);
        }
        if (openid) {
          app.$cookies.set('openid', openid);
        }
        app.router.replace({
          path: "/user/editProvince",
        });
        break;
      case 203:
        Dialog.alert({
          title: '提示',
          message: "请开通会员后，再进行操作",
        }).then(() => {
          app.router.replace({
            path: "/tools/vip",
          });
        });
        break;
      case 301:
        if (route.path == '/filling/filling' && response.data.message) {
          Dialog.confirm({
              title: '提示',
              message: response.data.message,
              confirmButtonText: '去完善信息',
              cancelButtonText: '看看其他的',
            })
            .then(() => {
              app.router.replace('/user/editProvince?type=province');
            })
            .catch(() => {
              app.router.replace('/');
            });
          response.data.data = [];
          return;
        } else {
          Dialog.confirm({
              title: '提示',
              message: '请先完善信息后再查看',
              confirmButtonText: '去完善信息',
              cancelButtonText: '看看其他的',
            })
            .then(() => {
              app.router.replace('/user/editProvince?backType=backHome');
            })
            .catch(() => {
              app.router.replace('/');
            });
          response.data.data = [];
          return;
        }
        break;
      case 303:
        Dialog.confirm({
            title: '提示',
            message: '24年大类已更新，请重新选择一级类',
            confirmButtonText: '去更新',
            cancelButtonText: '看看其他的',
          })
          .then(() => {
            app.router.replace('/user/editProvince?type=province');
          })
          .catch(() => {
            app.router.replace('/');
          });
        response.data.data = [];
        return;
        break;
      case 304:
        Dialog.confirm({
            title: '提示',
            message: '推荐已更新,请重新完善分数信息',
            confirmButtonText: '去完善',
            cancelButtonText: '看看其他的',
          })
          .then(() => {
            app.router.replace('/user/editScore?type=culture');
          })
          .catch(() => {
            app.router.replace('/');
          });
        response.data.data = [];
        return;
        break;
      case 302:
        store.dispatch("WXMEBOath");
        break;
      case 401:
        app.$cookies.set('token', '');
        if (process.client) {
          Toast('请重新登录');
          localStorage.clear();
          app.$cookies.set('href', window.location.href);
        }
        // console.log(app.context.route.path.indexOf('login'));
        // if(app.context.route.path.indexOf('login') == -1){
        //   app.router.replace('/login/login');
        // }
        // console.log(app.router)
        redirect('/login/login');
        // app.router.replace('/login/login').catch((res) => {
        //   console.log(res)
        // });
        break;
      case 500:
        if (response.data.message == '24年大类已更新，请重新保存报考方向和分数后再进行推荐。' || response.data.message == '请先完善信息') {
          Dialog.confirm({
              title: '提示',
              message: response.data.message,
              confirmButtonText: '去完善信息',
              cancelButtonText: '看看其他的',
            })
            .then(() => {
              app.router.replace('/user/editProvince?type=province');
            })
            .catch(() => {
              app.router.replace('/');
            });
          response.data.data = [];
          return;
        } else {
          Dialog.alert({
            title: '提示',
            message: response.data.message,
          });
          // Toast(response.data.message);
        }
        break;
      default:
        return response;
        break;
    }
  })

}
