const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['dist/unknownRoute.dev'] = require('../middleware/dist/unknownRoute.dev.js')
middleware['dist/unknownRoute.dev'] = middleware['dist/unknownRoute.dev'].default || middleware['dist/unknownRoute.dev']

middleware['unknownRoute'] = require('../middleware/unknownRoute.js')
middleware['unknownRoute'] = middleware['unknownRoute'].default || middleware['unknownRoute']

middleware['user-agent'] = require('../middleware/user-agent.js')
middleware['user-agent'] = middleware['user-agent'].default || middleware['user-agent']

export default middleware
